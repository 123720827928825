import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import {
	videoWrapper,
	videoOverlay,
	h1404,
	controlsWrapper,
	mailLink,
} from './404.module.css';

const NotFoundPage = () => {
	return (
		<main>
			<Seo />
			<div className={videoWrapper}>
				<div id="videoOverlay" className={videoOverlay}>
					<h1>
						<span>DIE ENTSCHEIDUNG</span> – Wilm führte ein Doppelleben
					</h1>
					<h1 className={h1404}>404</h1>
					<div className={controlsWrapper}>
						<h2>
							Die von Ihnen gesuchte Seite ist nicht verfügbar.
							<br />
							<Link to="/">Hier</Link> gelangen Sie zur Startseite.
						</h2>

						<a href="mailto:info@die-entscheidung.org" className={mailLink}>
							info@die-entscheidung.org
						</a>
					</div>
				</div>
			</div>
		</main>
	);
};

export default NotFoundPage;
